import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Hyde({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <section className="intro">
        <div
          className="intro-background"
          style={{
            backgroundColor: styles.forElement("intro_background")
              .backgroundColor
          }}
        />
        <div
          className="intro-background"
          style={styles.forElement("intro_background")}
        />
        <div className="formkit-container">
          <Elements.Image
            className="formkit-image formkit-hero-image"
            name="image"
            size={{ h: 740, w: 600 }}
            defaults={{
              src:
                "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/iArqs8fwaW5QeRxRRzyNnZ"
            }}
          />
          <div className="formkit-hero-intro">
            <div
              className="formkit-header-accent"
              style={styles.forElement("accent")}
            />
            <Elements.Heading
              className="formkit-heading formkit-heading-primary"
              name="header"
              defaults={{
                content: "Download the first chapter of When Me Became Mom"
              }}
            />
            <Elements.Heading
              className="formkit-heading-secondary"
              name="subheader"
              tag={"h2"}
              defaults={{
                content:
                  "The debut book from Jada Washington: single mama, writer, speaker, and activist. When Me Became Mom looks at the role of moms throughout history and the particular experience of African-American mothers, interwoven with Jada's own journey into motherhood."
              }}
            />

            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields data-stacked={stackedDisplay}>
                {stackedDisplay && <Elements.AddFieldButton />}
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Get the chapter" }}
                />
              </Elements.CustomFields>
              {!stackedDisplay && <Elements.AddFieldButton />}
            </Elements.Form>

            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at anytime."
              }}
            />
          </div>
        </div>
      </section>

      <section className="main" style={styles.forElement("main_background")}>
        <div className="formkit-container formkit-columns">
          <div className="formkit-column">
            <Elements.Image
              className="formkit-image"
              name="image_one"
              size={{ h: 50, w: 50 }}
              defaults={{
                src:
                  "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/wmZjZUeGqKWN47iEEetD2x"
              }}
            />
            <Elements.Heading
              className="formkit-heading formkit-heading-tertiary"
              name="column_1_heading"
              group="content_header"
              tag={"h2"}
              defaults={{ content: "The History of Motherhood" }}
            />
            <Elements.Region
              className="formkit-content"
              name="column_1_content"
              group="content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "When did me become mom? Has it always been the role of a woman to become mother? Will it always be that way? In this book, I explore the history of motherhood and look to the future to explore our roles."
                }}
              />
            </Elements.Region>
          </div>
          <div className="formkit-column">
            <Elements.Image
              className="formkit-image"
              name="image_two"
              size={{ h: 50, w: 50 }}
              defaults={{
                src:
                  "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/cJfcZeiAxy2EBw23MrCkiy"
              }}
            />
            <Elements.Heading
              className="formkit-heading formkit-heading-tertiary"
              name="column_2_heading"
              group="content_header"
              tag={"h2"}
              defaults={{ content: "African-American Mamas" }}
            />
            <Elements.Region
              className="formkit-content"
              name="column_2_content"
              group="content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "African-American mothers have a unique experience that needs to be acknowledged. This book addresses the particular issues, situations, and biases that affect our lives and our roles as mothers."
                }}
              />
            </Elements.Region>
          </div>
          <div className="formkit-column">
            <Elements.Image
              className="formkit-image"
              name="image_three"
              size={{ h: 50, w: 50 }}
              defaults={{
                src:
                  "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/5iqhEHqpWiqMHT1YLdY7fb"
              }}
            />
            <Elements.Heading
              className="formkit-heading formkit-heading-tertiary"
              name="column_3_heading"
              group="content_header"
              tag={"h2"}
              defaults={{ content: "My Personal Journey" }}
            />
            <Elements.Region
              className="formkit-content"
              name="column_3_content"
              group="content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "It's impossible to write about motherhood as an African-American woman without mentioning my own story. By sharing my journey, I hope to illustrate the realities of when me became mom for me."
                }}
              />
            </Elements.Region>
          </div>
        </div>
      </section>

      <footer className="formkit-footer" style={styles.forElement("footer")}>
        <div className="formkit-container">
          <Elements.Content
            name="copyright"
            defaults={{
              content: `All rights reserved`
            }}
          />
          <Elements.BuiltWith background="footer" />
        </div>
      </footer>
    </Elements.LandingPage>
  )
}

Hyde.style = "landing_page"
Hyde.categories = ["eBook", "Podcast", "Product"]
Hyde.thumbnail = ""
Hyde.preview = "https://demo.ck.page/hyde"
Hyde.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Hyde, { name: "Hyde" })
